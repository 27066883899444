export const TokenPillStyle = {
  LIGHT: 0, // blue border, white icon background
  DARK: 1, // blue border, blue icon background
  FUNCTION_VARIABLE: 2, // green border, white icon background
};

export const TokenPillLayout = {
  // single line pill
  SIMPLE: 0,

  // stretch vertically to fit up to 2 lines of content
  MULTI_LINE: 1,

  // pill breaks to wrap onto multiple lines
  INLINE: 2,
};

export const mapPropertyType = property => {
  if (property.type === 'string') {
    if (property.format === 'date-time') {
      return 'Datetime';
    }
    if (property.format === 'date') {
      return 'Date';
    }
  }
  return property.type;
};

export const getTokenPillInnerHtml = ({
  content = '',
  isChild = false,
  isParent = false,
  disabled = false,
  pillStyle = TokenPillStyle.DARK,
  pillLayout = TokenPillLayout.INLINE,
  classes = '',
  datatype,
  dataTypeText,
  source,
  iconRef = '',
  showTooltip = true,
}) => {
  return `<nx-token-pill
              show-tooltip=${showTooltip}
              data-type-text="${dataTypeText || mapPropertyType(datatype)}"
              source-text="${source}"
              content="${content}"
              pill-style="${pillStyle}"
              pill-layout="${pillLayout}"
              icon="${iconRef}"
              disabled=${disabled}
              is-child="${isChild}"
              is-parent="${isParent}"
              classes="${classes}">
          </nx-token-pill>`;
};
