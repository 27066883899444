/* eslint-disable camelcase */
import cloneDeep from 'lodash/fp/cloneDeep';
import {
  filterVariables,
  getSourceDisplayName,
  defaultResolveIcon,
} from './variablesConverter';
import { version } from '../package.json';

import { iconMap, EDITOR_TYPES } from './plugins/constants';

const CACHE_VERSION = version;

const NX_VARIABLE_PLUGINS = ['nx-autocomplete'];
const NX_PLACEHOLDER_DATA_ATTR = 'data-text-placeholder';

const NX_CKEDITOR_BASIC_CLASS = 'nx-ckeditor-basic';

const DELETE_KEY = 46;
const BACKSPACE_KEY = 8;

export const setStylePath = path => {
  CKEDITOR.nx = CKEDITOR.nx || {};
  CKEDITOR.nx.stylePath = path;
};

export const setVariableIconsPath = path => {
  CKEDITOR.nx = CKEDITOR.nx || {};
  CKEDITOR.nx.variableIconsPath = path;
};

export const setWebComponentsPath = (path, svgSheets) => {
  CKEDITOR.nx = CKEDITOR.nx || {};
  CKEDITOR.nx.webComponentsPath = path;
  CKEDITOR.nx.webComponentsSvgSheets = svgSheets;
};

/**
 * Set the variables for the editor and any link editor
 *
 * @param {CKEditorInstance} editor The editor
 * @param {array} variables The variables array
 *
 * @return {void}
 */
const setVariablesForNxAutoComplete = (editor, variables) => {
  let filtered = variables;
  const nxAutoComplete = editor.plugins['nx-autocomplete'];
  if (!nxAutoComplete) {
    return;
  }

  if (editor.config.variableFilterType) {
    filtered = filterVariables(variables, editor.config.variableFilterType);
  }

  filtered = filtered.filter(v => !v.isDeleted && !v.isOutOfScope);

  nxAutoComplete.setVariables(editor, filtered);

  if (editor.textEditor) {
    editor.textEditor.setVariables(variables);
  }

  if (editor.linkEditor) {
    editor.linkEditor.setVariables(variables);
  }
};

/**
 * Get the variables that have been set on an editor
 *
 * @param {CKEditorInstance} editor The editor
 *
 * @return {array}
 */
const getVariablesFromAutoComplete = editor => {
  const nxAutoComplete = editor.plugins['nx-autocomplete'];
  if (!nxAutoComplete) {
    return [];
  }

  return nxAutoComplete.getVariables(editor);
};

const destroyAutoComplete = editor => {
  const nxAutoComplete = editor.plugins['nx-autocomplete'];
  if (!nxAutoComplete) {
    return;
  }

  nxAutoComplete.destroyAutoComplete();
};

const deriveConfig = (defaultConfig, config) => {
  const derivedConfig = { ...defaultConfig, ...config };

  if (config.disableVariables) {
    derivedConfig.extraPlugins = config.extraPlugins
      .split(',')
      .filter(item => !NX_VARIABLE_PLUGINS.includes(item))
      .join(',');
  }

  return derivedConfig;
};

const pluginsToRemove = config =>
  `elementspath,resize,placeholder,contextmenu,magicline${
    !config.addWordCount ? ',wordcount' : ''
  }`;

export const initializeEditor = (element, config = {}, inline = false) => {
  CKEDITOR.timestamp = CACHE_VERSION;
  CKEDITOR.skin.name = 'nintex';
  CKEDITOR.config.skin = 'nintex';
  CKEDITOR.disableAutoInline = true;
  CKEDITOR.nx = CKEDITOR.nx || {};
  const styles = CKEDITOR.nx.stylePath || 'styles.css';
  const variableIcons = CKEDITOR.nx.variableIconsPath || 'variable-icons.css';
  const variables = cloneDeep(config.variables);
  const defaultConfig = {
    extraPlugins: 'nx-variable',
    bodyClass: 'nx-ckeditor',
    contentsCss: [
      `${styles}?v=${CACHE_VERSION}`,
      `${variableIcons}?v=${CACHE_VERSION}`,
    ],
    removePlugins: pluginsToRemove(config),
    removeButtons: 'Subscript,Superscript',
    skin: 'nintex',
    toolbar: [
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'FontSize',
          'NumberedList',
          'BulletedList',
          'Indent',
          'Outdent',
        ],
      },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'right', items: [] },
    ],
    fontSize_sizes:
      '8/8px;10/10px;12/12px;14/14px;16/16px;18/18px;20/20px;24/24px;26/26px;28/28px;36/36px;48/48px;',
    font_names: "'Open Sans', Helvetica, Arial, sans-serif",
    entities: false,
    title: 'Rich Text Editor',
    variablesOnly: false,
    inlineLinkForm: true,
    linkVariables: true,
    disableVariables: false,
    ignoreScrollableParents: [],
    fallbackScrollableParents: [],
    on: {
      instanceReady: evt => {
        const { editor } = evt;

        // Add the basic style
        if (editor.config.bodyClass.match(/nx-ckeditor-basic/)) {
          editor.container.$.classList.add(NX_CKEDITOR_BASIC_CLASS);
        }

        if (config.initialHtml) {
          editor.setData(config.initialHtml);
        }

        // Add the form control
        if (inline) {
          editor.checkEmptyData();

          if (config.inlineClass) {
            editor.container.$.classList.add(config.inlineClass);
            editor.container.$.classList.remove('cke_textarea_inline');
          }
        } else {
          const css = document.createElement('link');
          css.setAttribute('type', 'text/css');
          css.setAttribute('rel', 'stylesheet');
          css.setAttribute(
            'href',
            'https://fonts.googleapis.com/css?family=Montserrat:300,400|Open+Sans:400,400i,600,700&amp;subset=latin-ext'
          );
          editor.editable().$.appendChild(css);
        }

        // Set the placeholder if it hasn't been overwritten
        if (config.placeholder && !editor.placeholderOverwritten) {
          editor.container.$.setAttribute(
            NX_PLACEHOLDER_DATA_ATTR,
            config.placeholder
          );
        }

        if (variables && variables.length) {
          setVariablesForNxAutoComplete(evt.editor, variables);
          editor.nxData.variables = variables;
        }

        // Set a new filter for the variables
        editor.setVariableFilter = filter => {
          editor.config.variableFilterType = filter;
          editor.nxData.config.variableFilterType = filter;
        };

        // Add a function to update the variables that are available
        editor.setVariables = vars => {
          setVariablesForNxAutoComplete(editor, cloneDeep(vars));
          editor.nxData.variables = cloneDeep(vars);
        };

        // Get the variables assigned to this editor
        editor.getVariables = () => {
          return getVariablesFromAutoComplete(editor);
        };

        editor.getAllVariables = () => {
          return editor.nxData.variables || [];
        };

        editor.nxData.iconMap = config.iconMap || iconMap;

        const toolbar = editor.container.$.getElementsByClassName('cke_top')[0];
        if (toolbar) {
          toolbar.classList.add('visible');
        }
      },
      paste: evt => {
        evt.editor.nxData.pasteHappend = true;
      },
      key: evt => {
        const isKeyForDeletion =
          evt.data.keyCode === DELETE_KEY || evt.data.keyCode === BACKSPACE_KEY;
        if (isKeyForDeletion) {
          evt.editor.nxData.hasDeleted = true;
          if (evt.editor.nxData.pasteHappend) {
            setTimeout(() => {
              editor.fire('change');
              evt.editor.nxData.pasteHappend = false;
            });
          }
        }
      },
      change: evt => {
        if (!evt.editor.nxData.hasDeleted) {
          return;
        }
        evt.editor.checkEmptyData();
        evt.editor.nxData.hasDeleted = false;
      },
    },
  };
  const editorType = inline ? EDITOR_TYPES.INLINE : EDITOR_TYPES.REPLACE;
  const editorConfig = deriveConfig(defaultConfig, config);
  const editor = CKEDITOR[editorType](element, editorConfig);

  // Set the placeholder dynamically
  editor.setPlaceholder = value => {
    editor.placeholderOverwritten = true;
    if (value) {
      editor.container.$.setAttribute(NX_PLACEHOLDER_DATA_ATTR, value);
    } else {
      editor.container.$.removeAttribute(NX_PLACEHOLDER_DATA_ATTR);
    }
  };

  // Destroy the autocomplete menu
  editor.destroyAutoComplete = () => {
    destroyAutoComplete(editor);
  };

  editor.getSourceDisplayName = source =>
    getSourceDisplayName(source, editor.nxData.sourceDisplayNames);

  editor.checkEmptyData = () => {
    if (
      editor.status !== 'ready' ||
      editor.nxData.editorType !== EDITOR_TYPES.INLINE
    ) {
      return;
    }
    const initialData = editor.getData();
    if (!initialData) {
      editor.container.$.innerHTML = '';
    }
  };

  if (config.resolveIcon) {
    editor.resolveIcon = config.resolveIcon;
  } else {
    // using default resolveIcon
    editor.resolveIcon = defaultResolveIcon;
  }

  editor.nxData = {};
  editor.nxData.config = editorConfig;
  editor.nxData.editorType = editorType;
  editor.nxData.sourceDisplayNames = editorConfig.sourceDisplayNames;
  editor.nxData.iconMap = editorConfig.iconMap;

  return editor;
};

export const initializeBasicEditor = (element, config = {}) => {
  const defaultConfig = {
    extraPlugins: 'nx-variable,nx-fullscreen,nx-autocomplete,nx-fullscreen',
    bodyClass: 'nx-ckeditor nx-ckeditor-basic',
    toolbar: [
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'FontSize',
          'NumberedList',
          'BulletedList',
          'Outdent',
          'Indent',
        ],
      },
      { name: 'links', items: ['Link'] },
      { name: 'right', items: ['NxFullScreen'] },
    ],
    autoGrow_maxHeight: 200,
  };

  return initializeEditor(element, { ...defaultConfig, ...config });
};

export const initializeAdvancedEditor = (element, config = {}) => {
  const defaultConfig = {
    extraPlugins: 'nx-link,nx-variable,nx-autocomplete',
    toolbar: [
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'FontSize',
          'NumberedList',
          'BulletedList',
          'Outdent',
          'Indent',
        ],
      },
      { name: 'links', items: ['Link', 'NxLink', 'Unlink'] },
      { name: 'right', items: [] },
    ],
    autoGrow_maxHeight: 200,
  };

  return initializeEditor(element, { ...defaultConfig, ...config });
};

export const initializeInlineEditor = (element, config = {}) => {
  const defaultConfig = {
    extraPlugins: 'nx-variable,nx-noop,nx-autocomplete',
    toolbarLocation: 'none',
    toolbar: [],
    inlineClass: 'form-control',
    autoParagraph: false,
    keystrokes: [
      [13, 'nx-noop'],
      [CKEDITOR.SHIFT + 13, 'nx-noop'],
    ],
    allowLineBreaks: false,
  };

  return initializeEditor(element, { ...defaultConfig, ...config }, true);
};
