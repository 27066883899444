import cloneDeep from 'lodash/fp/cloneDeep';
import { placeholderReplaceRegex } from './plugins/constants';
import { escapeBasicEntities, encodeHrefVariables } from './textConverter';
import { getTokenPillInnerHtml, TokenPillStyle } from './tokenPillHtml';
import defaultIconMap from './defaultIconMap';
import { isTokenPillEnabled } from './feature-flags';

const pillRegEx = /<span class="nx-variable-highlight">.+?<\/span>/g;

/**
 * Get the source display name for a variable
 *
 * @param {string} source The source display name
 * @param {?object} sourceDisplayNames The source display names mapping
 *
 * @return {string}
 */
const getSourceDisplayName = (source, sourceDisplayNames = null) => {
  if (!sourceDisplayNames) {
    return source;
  }

  return sourceDisplayNames[source] || source;
};

/**
 * Get the property at a valuePath
 *
 * @param {object} variable The variable to find in
 * @param {string} valuePath The valuePath
 *
 * @return {?object}
 */
const getPathProperty = (variable, valuePath) => {
  const parts = valuePath
    .replace(/^\$\['/, '')
    .replace(/'\]$/, '')
    .split("']['");
  let item = variable;
  while (parts.length) {
    const part = parts.shift();
    const properties = item?.items || item?.properties || [];
    const found = properties.find(i => i.name === part || i.key === part);

    if (!found) {
      return null;
    }

    item = found;
  }

  return item;
};

/**
 * Get a variable by the value path
 *
 * @param {array} variables The array of variables
 * @param {string} value The value
 * @param {?string} valuePath The value path
 *
 * @return {?object}
 */
const getVariableByPath = (variables, value, valuePath) => {
  if (!variables || !variables.length) {
    return null;
  }
  const rootVariable = variables.find(v => v.key === value);
  if (!rootVariable) {
    return null;
  }

  const variable = valuePath
    ? getPathProperty(rootVariable, valuePath)
    : rootVariable;

  return variable
    ? {
        ...variable,
        valuePath,
        source: rootVariable.source,
        parentName: value,
      }
    : null;
};

/**
 * Removes non existing from provided html,
 * replacing them with their display name
 *
 * @param {string} data Editor's data string
 * @param {array} existingVariables All existing/valid variables
 * @param {bool} shouldEscape If the basic value entities should be escaped
 *
 * @return {string} Data with only valid variables
 */
const removeNonExistentVariables = (
  data,
  existingVariables,
  shouldEscape = false
) => {
  const matches = data.match(placeholderReplaceRegex);

  if (!matches || !matches.length) {
    return data;
  }
  const insertedVariables = matches.map(m => JSON.parse(m.slice(2, -2)));

  const cleanedVariables = insertedVariables.map(variable => {
    const variableExists = getVariableByPath(
      existingVariables,
      variable.internalName,
      variable.valuePath
    );

    if (!variableExists) {
      return variable.displayValue;
    }

    const variableJson = `[[${JSON.stringify(variable)}]]`;

    let html = variableJson;
    if (shouldEscape) {
      html = escapeBasicEntities(variableJson);
    }

    html = encodeHrefVariables(html);

    return html;
  });

  let cleanedUpData = data;
  matches.forEach((match, index) => {
    cleanedUpData = cleanedUpData.replace(match, cleanedVariables[index]);
  });

  return cleanedUpData;
};

/**
 * Filter the variables by type and removing deleted variables/properties
 * @param {array} variables The array of variables
 * @param {string|object|array} filters The effective filters
 *
 * @return {array} The filtered variables
 */
const filterVariables = (variables, filters = []) => {
  let types = filters;
  const filter = items => {
    return items.filter(variable => {
      const keep =
        types.includes(variable.type) &&
        !variable.isDeleted &&
        !variable.isOutOfScope;
      if (!keep) {
        return false;
      }

      if (variable.properties) {
        // eslint-disable-next-line no-param-reassign
        variable.properties = filter(variable.properties);
      }

      return true;
    });
  };

  if (
    !Array.isArray(filters) &&
    (typeof filters === 'string' ||
      (typeof filters === 'object' && filters !== null))
  ) {
    types = [filters];
  }

  if (!types || !types.length) {
    return variables;
  }

  types.push('Object');

  types = types.map(item =>
    typeof item === 'object' && item !== null ? item.name : item
  );

  const variablesClone = cloneDeep(variables);
  return filter(variablesClone);
};

/**
 * Convert html to element with varaibles in text format
 *
 * @param {string} data The data
 *
 * @return {dom element}
 */
const convertVariableHtmlToVariableElement = data => {
  const temp = document.createElement('div');
  temp.innerHTML = data;
  const widgets = temp.querySelectorAll('.cke_widget_nx-variable');
  Array.from(widgets).forEach(i => {
    const element = i.querySelector('.cke_widget_element');
    if (element) {
      const data1 = JSON.stringify({
        internalName: element.dataset.internalName,
        displayValue: element.dataset.displayValue,
        source: element.dataset.source,
        dataType: element.dataset.dataType,
        subType: element.dataset.subType,
        isDeleted: !!element.dataset.isDeleted,
        ...(element.dataset.isOutOfScope && { isOutOfScope: true }),
        ...(element.dataset.isChild && { isChild: true }),
        ...(element.dataset.isParent && { isParent: true }),
        valuePath: element.dataset.valuePath,
      });
      i.parentNode.replaceChild(document.createTextNode(`[[${data1}]]`), i);
    }
  });

  // Remove the temp data tags
  const temps = temp.querySelectorAll('[data-cke-temp]');
  Array.from(temps).forEach(i => i.parentNode.removeChild(i));

  return temp;
};

/**
 * Separate the variable from the string
 * This is used for variable or value fields
 *
 * @param {string} data The data to parse
 * @param {array} variables The array of variables
 *
 * @return {object}
 */
const separateVariable = (data, variables) => {
  const cleaned = data.replace(pillRegEx, '');
  const matches = cleaned.match(placeholderReplaceRegex);

  if (!matches) {
    return {
      value: cleaned,
    };
  }

  const variable = JSON.parse(matches[0].slice(2, -2));

  return {
    value: cleaned.replace(matches[0], '').trim(),
    variable: variables.find(i => i.key === variable.internalName),
    usage: variable,
  };
};

/**
 * Gets the full display name for a variable
 *
 * @param {array} variables Array of inner variables
 * @param {object} variable Inner variable object
 *
 * @return {string}
 */
const getFullDisplayName = (variables, variable) => {
  const found = variables.find(v => v.key === variable.key) || variable;
  if (!variable.valuePath) {
    return found.label;
  }
  const pathParts = variable.valuePath
    .replace(/^\$\['/, '')
    .replace(/'\]$/, '')
    .split("']['");
  let path = '';
  let fullDisplayName = [found.label];
  // eslint-disable-next-line no-restricted-syntax
  for (const pathPart of pathParts) {
    path = path ? `${path}['${pathPart}']` : `['${pathPart}']`;
    const innerVariable = getVariableByPath(variables, found.key, `$${path}`);
    if (innerVariable) {
      fullDisplayName = `${fullDisplayName} > ${innerVariable.label}`;
    } else {
      fullDisplayName = `${fullDisplayName} > ${pathPart}`;
    }
  }

  return `${fullDisplayName}`;
};

const getVariablePill = (
  inputVariable,
  resolveIcon,
  sourceDisplayName,
  variables
) => {
  const variable =
    getVariableByPath(
      variables,
      inputVariable.value,
      inputVariable.valuePath
    ) || inputVariable;

  variable.key = variable.parentName || variable.key;

  const fullName = getFullDisplayName(variables, {
    key: variable.key,
    valuePath: variable.valuePath,
    label: variable.label,
  });

  const aria = `Source: ${sourceDisplayName}, Name: ${fullName}`;
  const iconRef = resolveIcon && resolveIcon(variable);

  if (isTokenPillEnabled()) {
    return getTokenPillInnerHtml({
      disabled: !!(inputVariable.isDeleted || inputVariable.isOutOfScope),
      isChild: inputVariable.isChild,
      isParent: inputVariable.isParent,
      datatype: inputVariable,
      dataTypeText: inputVariable.dataTypeText,
      content: inputVariable.label,
      pillStyle: inputVariable.subType
        ? TokenPillStyle.LIGHT
        : TokenPillStyle.DARK,
      iconRef,
      source: inputVariable.source,
      showTooltip: inputVariable.showTooltip,
    });
  }
  const className = 'nx-pill-prepend';
  // eslint-disable-next-line max-len
  return `<span class="${className}"><img class="nx-img-icon-wrapper" src="${iconRef}"></span><span class="nx-pill-content" aria-label="${aria}" title="${aria}">&nbsp;&nbsp;${variable.label}&nbsp;&nbsp;</span>`;
};

export const defaultResolveIcon = rteVariable =>
  defaultIconMap[rteVariable.type];

export {
  removeNonExistentVariables,
  pillRegEx,
  filterVariables,
  convertVariableHtmlToVariableElement,
  separateVariable,
  getPathProperty,
  getVariableByPath,
  getSourceDisplayName,
  getVariablePill,
  getFullDisplayName,
};
